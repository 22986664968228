div.Polaris-Page {
  width: 100%;
  min-width: none;
  max-width: 100%;
  padding: 0, 0, 0, 0;
  margin: 0, 0, 0, 0;
}

div.Polaris-TextContainer {
  margin-left: 0px;
  margin-top: 4px;
}

span.commentAuthor {
  font-size: xx-small;
}
