.Polaris-Text--visuallyHidden {
  /* @TODO: Debug this*/
  display: none;
}
div.Polaris-Page {
  /* @TODO: This is broken somewhere, incorrectly nested components, quickfix for now */
  width: auto !important;
}
.Polaris-DataTable .Polaris-Icon {
  margin: 0;
}
.query-builder-container {
  padding: 0 !important;
}
.query-builder {
  margin: 0 0 20px!important;
}
#root {
  width: 100%;
  padding: 0;
  margin: 0;
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 9999;
}
.loader {
  left: 50%;
  margin-left: -4em;
  font-size: 10px;
  border: 0.8em solid rgba(218, 219, 223, 1);
  border-left: 0.8em solid rgba(58, 166, 165, 1);
  animation: spin 1.1s infinite linear;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 8em;
  height: 8em;
  display: block;
  position: absolute;
  top: 50%;
  margin-top: -4.05em;
}

@keyframes spin {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.wtta_Container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: 0px;
  margin-left: 0px;
  background: #f4f6f6;
}
.navbar {
  width: 100%;
}

body > iframe {
  display: none;
  visibility: hidden;
}
